import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const HomeHero = styled(Box)`
  ${({ theme: { breakpoints, spacing } }) => `   
    margin-left: -${spacing(3)}px;
    margin-right: -${spacing(3)}px;
    ${breakpoints.down('xs')} {
      margin-left: -${spacing(2)}px;
      margin-right: -${spacing(2)}px;
      padding-top: 20px;
    }    
  `}
`;

export const HomeHeroGrid = styled(Grid)`
  ${({ theme: { breakpoints } }) => `
    min-height: 45vw;
    ${breakpoints.up('md')} {
      min-height: 410px;
    }   
  `}
`;

export const Title = styled(Typography)`
  ${({ theme: { breakpoints, typography } }) => `
    line-height: 1.33;
    margin: 0 0 0.5em;
    ${breakpoints.down('sm')} {
      font-size: ${typography.pxToRem(28)};
    }
  `}
`;

export const Text = styled(Typography)`
  ${({ theme: { breakpoints } }) => `
    margin: 0;
    max-width: 320px;
    opacity: 0.8;
    ${breakpoints.down('xs')} {
      max-width: none;
    }
  `}
`;

export const GridText = styled(Grid)`
  ${({ theme: { breakpoints, spacing } }) => `
    padding: 90px 24px 50px 7vw;
    align-self: center;

    ${breakpoints.up('lg')} {
      padding-left: 100px;
    }

    ${breakpoints.down('xs')} {
      padding: 30px ${spacing(3)}px 30px ${spacing(2)}px;
    }
  `}
`;

export const GridImage = styled(Grid)`
  ${({ theme: { breakpoints, spacing } }) => `
    position: relative;
    padding: ${spacing(3)}px;
    ${breakpoints.down('xs')} {
      min-height: 255px;
      height: 60vw;
      padding: ${spacing(2)}px;
    }
  `}
`;

export const HeroImage = styled(Box)`
  ${({ theme: { breakpoints } }) => `    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${breakpoints.up('lg')} {
      bottom: -33%;
      left: calc(-1*(50vw - 640px));
    }
  `}
`;

import React, { memo } from 'react';
import Image from 'next/image';

import {
  HomeHero,
  HeroImage,
  HomeHeroGrid,
  Title,
  Text,
  GridText,
  GridImage,
} from './homeHeroSecond.styles';

export const HomeHeroSecond = memo(() => (
  <HomeHero>
    <HomeHeroGrid container alignItems="stretch" justify="space-between">
      <GridImage item xs={12} sm={7}>
        <HeroImage>
          <Image
            src="/images/hero-second.jpg"
            alt="Specialising in affordable homes"
            objectFit="cover"
            objectPosition="top center"
            layout="fill"
            priority
          />
        </HeroImage>
      </GridImage>
      <GridText item xs={12} sm={5}>
        <Title variant="h1" color="primary">
          Specialising <br />
          in affordable homes
        </Title>
        <Text variant="body1">
          Search new homes for sale including with shared ownership and other
          buying options, and rental schemes including Rent to Buy and London
          Living Rent. Find your new home with Keaze today from housing
          providers from across the country.
        </Text>
      </GridText>
    </HomeHeroGrid>
  </HomeHero>
));
